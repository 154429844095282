import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import {
  Grid,
  Card,
  ThumbnailImage,
  Text,
  TextTypography,
  Button,
  ButtonPriority,
  ImageResizeOptions,
  TPAComponentsProvider,
} from 'wix-ui-tpa';
import { Quiz } from '@wix/ambassador-quiz-v1-quiz/types';
import { st, classes } from './Widget.st.css';

export type ControllerProps = {
  items: (Quiz & { link: string })[];
};

const Widget: FC<WidgetProps<ControllerProps>> = ({ items }) => {
  const isMobile = false;
  return (
    <TPAComponentsProvider value={{ mobile: isMobile, rtl: false, seo: false }}>
      <Grid maxColumns={3} minColumnWidth={250}>
        {items.map((item) => (
          <Grid.Item colSpan={1} rowSpan={1}>
            <Card className={st(classes.root, {}, classes.card)} stacked>
              {item.image?.url ? (
                <Card.Container>
                  <ThumbnailImage
                    src={item.image.url}
                    alt="My product"
                    width={400}
                    height={520}
                    fluid
                    resize={ImageResizeOptions.cover}
                  />
                </Card.Container>
              ) : null}
              <Card.Container className={classes.info}>
                <div className={classes.grid}>
                  <div>
                    <Text
                      typography={TextTypography.smallTitle}
                      tagName="h3"
                      className={classes.title}
                    >
                      {item.title}
                    </Text>
                    <Text
                      typography={TextTypography.runningText}
                      tagName="div"
                      className={classes.details}
                    >
                      {item.test?.timeLimit
                        ? `${Math.floor(item.test.timeLimit / 60)} min | `
                        : null}
                      5 questions
                    </Text>
                  </div>
                  <div>
                    <a href={item.link} className={classes.link}>
                      <Button
                        priority={ButtonPriority.secondary}
                        className={classes.button}
                      >
                        Details
                      </Button>
                    </a>
                  </div>
                </div>
              </Card.Container>
            </Card>
          </Grid.Item>
        ))}
      </Grid>
    </TPAComponentsProvider>
  );
};

export default Widget;
